import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "hello",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#hello",
        "aria-label": "hello permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hello,`}</h1>
    <p>{`Welcome to my blog! I am Anjan, a front-end developer from India.
In my blog, I write about JavaScript and front-end programming articles to help other developers.`}</p>
    <h2 {...{
      "id": "my-algorithm-journey-in-javascript",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#my-algorithm-journey-in-javascript",
        "aria-label": "my algorithm journey in javascript permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`My Algorithm Journey in JavaScript`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-704-binary-search"
        }}>{`Leet Code 704: Binary Search`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-278-first-bad-version"
        }}>{`Leet Code 278: First Bad Version`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-35-search-insert-position"
        }}>{`Leet Code 35: Search Insert Position`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-977-squares-of-a-sorted-array"
        }}>{`Leet Code 977: Squares of a Sorted Array`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-189-rotate-array"
        }}>{`Leet Code 189: Rotate Array`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-283-move-zeroes"
        }}>{`Leet Code 283: Move Zeroes`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-167-two-sum-II"
        }}>{`Leet Code 167: Two Sum II - Input Array Is Sorted`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-344-reverse-string"
        }}>{`Leet Code 344: Reverse String`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "leet-code-557-reverse-words-in-a-string-III"
        }}>{`Leet Code 557: Reverse Words in a String III`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/leet-code-876-middle-of-the-linked-list"
        }}>{`Leet Code 876: Middle of the Linked List`}</a></li>
    </ol>
    <h2 {...{
      "id": "html-css",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#html-css",
        "aria-label": "html css permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTML CSS`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/generate-qr-code-javascript"
        }}>{`Generate a QR Code in Javascript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/custom-select-dropdown-using-html-css-javascript/"
        }}>{`Create a custom select dropdown using HTML, CSS and JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/html-rgb-color-codes-hex-name/"
        }}>{`HTML RGB color codes and their names`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/style-a-select-option-in-css/"
        }}>{`Quickly style a select option in CSS: Works under 5 mins`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/reusable-custom-select-element-in-javascript/"
        }}>{`Reusable Custom Select Element in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/styling-checkbox-input-using-html-and-css/"
        }}>{`Styling checkbox input using html and css`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/styling-radio-button-input-using-html-and-css/"
        }}>{`Styling radio button input using html and css`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/change-parent-element-property-on-focus-of-child-in-css/"
        }}>{`Change parent element property on focus of child in CSS`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/create-gradient-colored-text-in-css/"
        }}>{`Create gradient colored text in CSS`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/create-gradient-colored-background-in-css/"
        }}>{`Create gradient colored background in CSS`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/position-an-element-at-the-bottom-of-its-container-in-css/"
        }}>{`Position an element at the bottom of its container in CSS`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-fit-div-height-to-the-browser-window/"
        }}>{`How to fit div height to 100% of the browser window`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/vertically-and-horizontally-centre-content-inside-a-div/"
        }}>{`Vertically and horizontally centre content inside a div`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-create-a-animated-rotating-loader-in-html-and-css/"
        }}>{`How to create a animated rotating loader in html and css`}</a></li>
    </ol>
    <h2 {...{
      "id": "javascript",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#javascript",
        "aria-label": "javascript permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`JavaScript`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-create-trigger-custom-event/"
        }}>{`Create and Trigger Custom Event in Javascript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-es6-default-parameter/"
        }}>{`Default Parameter In JavaScript: What and How`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/extract-data-from-arrays-and-objects-in-javascript/"
        }}>{`Extract Data from Arrays and Objects in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/promise-in-javascript-for-beginners/"
        }}>{`Promise in JavaScript an introduction for beginners`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/foreach-in-javascript/"
        }}>{`forEach in JavaScript : 3 helpful things to learn`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-array-basic-things-to-know/"
        }}>{`Array in JavaScript, Things Worth Remembering`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/function-in-javascript/"
        }}>{`Function in Javascript a Complete Guide`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/objects-in-javascript-a-beginners-guide/"
        }}>{`Objects in JavaScript, a beginners guide`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/execution-context-in-javascript/"
        }}>{`Execution Context in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/scope-in-javascript-for-beginners/"
        }}>{`Scope in JavaScript an introduction for beginners`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/multiple-conditions-in-javascript-if-statement/"
        }}>{`Multiple Conditions in JavaScript if Statement`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/closure-in-javascript-for-beginners/"
        }}>{`Closure in JavaScript a Complete Definition`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/iife-javascript/"
        }}>{`JavaScript iife - Immediately Invoked Function Expression`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/reduce-in-javascript/"
        }}>{`Reduce in JavaScript`}</a></li>
    </ol>
    <h2 {...{
      "id": "how-tos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-tos",
        "aria-label": "how tos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to's`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-get-the-value-of-text-input-field-using-javascript/"
        }}>{`How to get the value of text input field using JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/explode-or-split-a-string-in-javascript/"
        }}>{`Explode or split a string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-compare-dates-in-javascript/"
        }}>{`How to compare dates in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/convert-number-to-string-in-javascript/"
        }}>{`Convert number to string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/remove-a-character-from-string-in-javascript/"
        }}>{`Remove a character from string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/add-elements-to-an-array-in-javascript/"
        }}>{`Add elements to an array in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-check-if-a-javascript-object-property-is-undefined/"
        }}>{`How to check if a JavaScript object property is undefined`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-open-url-in-a-new-tab-using-javascript/"
        }}>{`How to open URL in a new tab using JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-check-if-a-value-is-a-number-in-javascript/"
        }}>{`How to check if a value is a number in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-get-the-length-of-a-string-in-javascript/"
        }}>{`How to get the length of a string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/remove-the-last-character-of-a-string-in-javascript/"
        }}>{`Remove the last character of a string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-get-the-current-url-with-javascript/"
        }}>{`How to get the current URL with JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-check-if-a-javascript-array-is-empty-or-not/"
        }}>{`How to check if a JavaScript array is empty or not`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-get-index-from-a-json-object-with-value-javascript/"
        }}>{`How to get index from a JSON object with value: JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/convert-a-string-to-number-in-javascript/"
        }}>{`Convert a string to number in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/convert-string-to-date-in-javascript/"
        }}>{`3 easy ways to convert string to date in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/switch-on-ranges-and-multiple-conditions-in-javascript/"
        }}>{`Switch on ranges and multiple conditions in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-sort-array-of-objects/"
        }}>{`Sort array of objects in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/get-length-of-object-javascript/"
        }}>{`Get length of object JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/string-contains-javascript/"
        }}>{`Check if string contains substring JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/remove-a-specific-item-from-an-array-in-javascript/"
        }}>{`Remove a specific item from an array in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/comparing-equality-and-identity-operator-in-javascript/"
        }}>{`Comparing Equality Operator and Identity  Operator  in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/check-if-object-is-empty-javascript/"
        }}>{`Check if object is empty JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/remove-last-element-from-array-in-javascript/"
        }}>{`Remove last element from an array in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/insert-an-item-at-a-specific-index-of-an-array-in-javascript/"
        }}>{`Insert an item at a specific index of an array in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/replace-all-occurrences-of-a-string-in-javascript/"
        }}>{`Replace all occurrences of a string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/check-if-an-array-includes-a-value-in-javascript/"
        }}>{`Check if an array includes a value in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/remove-space-from-string-in-javascript/"
        }}>{`Remove space from string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-loop-through-object/"
        }}>{`JavaScript loop through object`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-remove-an-element-from-array-in-javascript/"
        }}>{`How to remove an element from array in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-deep-clone-object/"
        }}>{`JavaScript Deep Clone an Object`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/check-if-key-exists-in-object-javascript/"
        }}>{`Check if key exists in object JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-merge-objects/"
        }}>{`JavaScript merge objects`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-check-if-object-has-property/"
        }}>{`JavaScript check if object has property`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/javascript-remove-object-property/"
        }}>{`JavaScript remove object property`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/capitalize-the-first-letter-of-every-word-in-javascript/"
        }}>{`Capitalize first letter of every word in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-run-a-function-when-the-page-is-loaded-in-javascript/"
        }}>{`How to run a function when the page is loaded in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-do-email-validation-in-javascript/"
        }}>{`How to do an email validation in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-do-phone-number-validation-in-javascript/"
        }}>{`How to do phone number validation in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-disable-a-button-using-javascript/"
        }}>{`How to disable a button using JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-capitalize-the-first-letter-of-any-string-in-javascript/"
        }}>{`How to capitalize the first letter of any string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/uninstall-npm-package/"
        }}>{`How to uninstall NPM package`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/difference-between-npm-install-save-and-save-dev/"
        }}>{`What is the difference between npm install --save and --save-dev`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/back-to-top-link/"
        }}>{`Back to top link using html and JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/check-dom-content-loaded-javascript/"
        }}>{`How to check if DOM content is loaded in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/test-string-palindrome-in-javascript/"
        }}>{`How to test string palindrome in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/reverse-string-in-javascript/"
        }}>{`How to reverse a string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/how-to-include-javascript-code-in-html/"
        }}>{`How to include JavaScript in HTML`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/convert-array-to-string-javascript/"
        }}>{`How to convert an Array to a String in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/sum-of-array-items-in-javascript/"
        }}>{`Sum of all array items in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/check-if-url-contains-string-javascript/"
        }}>{`Check if URL contains string in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/store-retrieve-object-in-localstorage-javascript/"
        }}>{`How to store and retrieve object in locals torage JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/access-last-element-of-array-javascript/"
        }}>{`Get the last element of an array in JavaScript`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/get-current-year-in-javascript/"
        }}>{`How to get the current year in JavaScript`}</a></li>
    </ol>
    <h2 {...{
      "id": "linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux",
        "aria-label": "linux permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Linux`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/copy-file-in-linux/"
        }}>{`Copy files from one directory to another in Linux`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/move-file-in-linux/"
        }}>{`Move files from one directory to another in Linux`}</a></li>
    </ol>
    <h2 {...{
      "id": "random",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#random",
        "aria-label": "random permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Random`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/blog-reached-hundred-visitors-a-day/"
        }}>{`My blog reached 100 visitors a day`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/work-life-balance/"
        }}>{`Work life balance`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/moved-my-blog-from-wordpress-to-serverless/"
        }}>{`Moved my blog from Wordpress to Serverless`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/i-am-enjoying-the-aws-world/"
        }}>{`I am enjoying the AWS world`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/setup-ci-cd-pipeline-for-blog/"
        }}>{`I've setup CI/CD pipeline for my blog!!!`}</a></li>
    </ol>
    {/*
     ## General
     1. [4 attributes of a good programmer](/attributes-of-a-good-programmer)
     1. [Angular Interceptor not Adding Header](/angular-interceptor-not-adding-header)
     ## Datastructure
     1. [List data structure for beginners, a complete guide](/list-data-structure-for-beginners-a-complete-guide)
     1. [Linked list data structure for beginners](/linked-list-data-structure-for-beginners) 
     */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      